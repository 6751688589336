<template>
  <div class=" h-100-vh ">

    <div class="row justify-content-end align-items-end">
      <div class="col-12 col-md-4 form-rounded-inputs order-1 order-md-2">
        <div class="form-group animated fadeIn ">
          <label for="">
            <span v-text="$t('dashboard.orders.country')"></span>
            <i class=" fa fa-question-circle text-info ml-1"
              v-b-popover.hover.bottom=" $t('dashboard.shipping.carriersServicesMessage')"></i>
          </label>
          <div class="input-group">
            <select class="custom-select" v-model="carriersPagination.country " @change="fnApiGetCarriers()">
              <option :value="null" disabled v-text="$t('general.form.select')"> </option>
              <option :value="country.code" v-for=" (country,index) in countriesList " :key="index"
                v-text=" country.name ? country.name : country.code"> </option>
            </select>
            <div class="input-group-append">
              <button class="btn btn-success" type="button"> <i class="fa fa-flag"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ShippingCarriers v-if=" carriersPagination.country != null" />
  </div>
</template>

<script>
  import {
    mapActions,
    mapState
  } from 'vuex';
  import ShippingCarriers from './components/shipping-carriers.vue';
  export default {
    components: {
      ShippingCarriers,
    },
    data() {
      return ({
        storesList: [],
        countriesList: [],
        loading: true,
        pagination: {
          storeId: null,
          country: 'MX',
        }
      })
    },
    computed: {
      ...mapState('EcartCarriers', ['carriersPagination']),
    },
    methods: {
      ...mapActions('EcartCarriers', ['fnApiGetCarriers']),
      async fnApiGetStores() {
        this.loading = true;
        await axios.get('stores?limit=40').then((response) => {
          if (response.data.length > 1) {
            this.pagination.storeId = response.data[0].id;
            this.storesList = response.data;
          }
        }).catch();
        this.loading = false;
      },
    },
    async mounted() {
      // this.fnApiGetStores();
      this.countriesList = await this.fnApiGetCountries();
    }
  }
</script>